#acerca-video{
    position: relative;
    canvas {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }
    .slide-wrapper {
        display: none;
    }
    .bg-overlay{
        z-index: 1;
        opacity: .3;
        background: rgba(0, 0, 0, .3);
    }
    .container-wide{
        z-index: 2;

    }
    
}
    #experience{
        background-color: #014E9A;
            
        
    }

