.blog-comments-custom{
    
    .container{
        
        height: auto !important;
    }
    .ha{
        height: auto !important;
    }
    .hm-i{
        max-height: inherit  !important;

    }
}
#header.blog-comments{
    background-color: #1e2022 !important;
}