/*--------------------------------------------------------
     Tabs
---------------------------------------------------------*/
//chkd
//Tabs
$nav-tabs-border-width: $border-width;
$nav-tabs-border-color: $border-color;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $link-hover-color;
$nav-tabs-link-color: $body-color;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $border-color;

ul.nav li.dropdown:hover>ul.dropdown-menu {
    display: block;
}

.tabs {
    .nav-tabs {
        border-bottom: 2px solid $nav-tabs-border-color;
        margin-bottom: 30px;

        .nav-item {
            margin-bottom: - 2px;
            margin-inline: 2px;
            margin-bottom: 10px;

        }

        .nav-link {
            color: $body-color;
            
            color: rgba($body-color, .8);
            transition: 0.3s ease;
            font-weight: 500;
            border: 0;
            /*border: $nav-tabs-border-width solid transparent;*/
            border-top-left-radius: $nav-tabs-border-radius;
            border-top-right-radius: $nav-tabs-border-radius;
            border-bottom: 2px solid $nav-tabs-border-color;

            i {
                margin-right: 6px;
            }

            &.disabled {
                color: rgba($body-color, .5);
            }
        }

        .nav-link.active {
            color: $theme;
            background-color: $nav-tabs-link-active-bg;
            border-bottom: 2px solid $theme;
        }

        .dropdown-menu {
            border-radius: $nav-tabs-border-radius;
            border: 0;
            box-shadow: $box-shadow;
        }
    }

    &.tabs-clean {
        .nav-tabs {
            border-bottom: 0;

            .nav-link {
                border: $nav-tabs-border-width solid transparent;
                border-bottom-left-radius: $nav-tabs-border-radius;
                border-bottom-right-radius: $nav-tabs-border-radius;
                
            }

            .nav-1{
                background-color: $indigo;
                color: white;
            }
            .nav-2{
                background-color: $aqua;
                color: white;
            }
            @include media-breakpoint-down(md) {
                
                display: inline-block;
              }
            
        }
    }

    &.tabs-vertical {
        .nav-tabs {
            border: 0;
            margin-bottom: 0;

            .nav-link {
                border-radius: 0;
                border-bottom: 0;
                border-right: 2px solid $nav-tabs-border-color;
            }

            .nav-link.active {
                color: $theme;
                background-color: $nav-tabs-link-active-bg;
                border-right: 2px solid $theme;
            }
        }
    }

    &.tabs-folder {
        .nav-tabs {
            margin-bottom: 0;
            border-bottom: 0;

            .nav-item {
                margin-bottom: 0;
            }

            .nav-link {
                border: $nav-tabs-border-width solid transparent;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .nav-link.active {
                border-color: $nav-tabs-link-active-border-color;
                border-bottom-color: $white;
            }
        }

        .tab-content {
            margin-top: -1px;
            border: $nav-tabs-border-width solid $nav-tabs-border-color;
            bottom: 10px;
            padding: 30px;
            border-radius: 0 6px 6px 6px;
        }
    }
}