/*!
 * Polo components
 */

//@import "components/reboot";
//@import "components/type";
//@import "components/images";
//@import "components/code";
//@import "components/grid";
//@import "components/tables";


@import "components/forms";
@import "components/custom-forms";
//@import "components/buttons";
//@import "components/transitions";
@import "components/dropdown";


//@import "components/button-group"; 
//@import "components/input-group";

@import "components/nav";
//@import "components/navbar";
@import "components/card";
//@import "components/breadcrumb";
//@import "components/pagination";
@import "components/badge";
@import "components/jumbotron";

//@import "components/alert";
//@import "components/progress";
//@import "components/media";
//@import "components/list-group";
//@import "components/close";
//@import "components/toasts";
//@import "components/modal";
//@import "components/tooltip";
//@import "components/popover";
//@import "components/carousel"; 
//@import "components/spinners";
//@import "components/print";

@import "components/utilities";

//Polo components
//@import "components/text";
//@import "components/steps";
//@import "components/scrolltotop";
//@import "components/figure";