.classes-page-custom{

    .block-stop-bebes{
        height: 320px !important;
    
        @include media-breakpoint-down(lg){
           //line-height: 100px;
            height: 0px !important;
       }
       
     }
     .block-stop-ninos{
        height: 350px !important;
    
        @include media-breakpoint-down(lg){
           //line-height: 100px;
            height: 0px !important;
       }
     }
     .block-stop-adultos{
        height: 330px !important;
    
        @include media-breakpoint-down(lg){
           //line-height: 100px;
            height: 0px !important;
       }
     }
}

