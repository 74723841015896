/*

Polo - Multipurpose HTML5 Template
Version: 0.1
Website: https://webdcero.com
Author: Uriel Acosta
Copyright 2023

*/

//Google Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,400,500,600,700,800');
@font-face {
    font-family: 'VAG Rounded Bold';
    src: url('../assets/fonts/VAGRoundedBold.ttf');
    font-display: swap;
   }
// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/functions";

// Polo variables
@import "variables";

// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
//@import "../../node_modules/bootstrap/scss/utilities";
 
// Mixins
@import "mixins";

// Bootstrap optional
//@import "bootstrap";

// Polo 
//@import "core"; 
//@import "layout"; 
 
// utilities
@import "utilities"; 

// widgets
@import "widgets"; 
 
// Base 
@import "base/include";

// Vendor  
@import "vendor"; 
 
// Vendor plugin theme
@import "components"; 

// Elements
@import "elements/include";

// Importar los estilos SCSS de Slider Revolution
//@import "../../node_modules/revslider/scss/settings.scss"
@import "../vendors/rev-slider/scss/settings.scss";
@import "../vendors/rev-slider/scss/customRevslider.scss";
@import "../vendors/rev-slider/revolution-addons/polyfold/css/revolution.addon.polyfold.css";

@import "elements/testimonials";


@import "../vendors/GridRevealEffects-master/scss/base.scss";

@import "../vendors/Vertical-Swiper/scss/style.scss";

@import "../scss/base/prices";
@import "../scss/base/classes";
@import "../scss/base/contact";	
@import "../scss/base/convenios";
@import "../scss/base/promociones";
@import "../scss/base/blog-comments";
// Resto de tus estilos y código SCSS
// ...
