// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/functions";
// Polo variables
@import "variables";
// Bootstrap Configuration
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/maps";

@import "../../node_modules/bootstrap/scss/utilities";
// Mixins
@import "mixins";

// Bootstrap optional  
@import "bootstrap";



$fa-font-path: "../assets/webfonts";
// importing core styling file
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
// our project needs Classic Solid, Brands, and Sharp Solid 
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import '../../node_modules/swiper/swiper-bundle.css';



// Animate.css
@import "../../node_modules/animate.css/animate.min";


// Animate.css
@import "../../node_modules/flickity/dist/flickity.min.css";
 