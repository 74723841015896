/* ----------------------------------------------------------------------
	 Hover animation
-------------------------------------------------------------------------*/


  /* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
  @keyframes MoveUpInitial {
    to {
      transform: translate3d(0, -105%, 0);
    }
  }
  @keyframes MoveUpEnd {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes marquee {
    0% {
      transform: translate3d(var(--move-initial), 0, 0);
    }
    100% {
      transform: translate3d(var(--move-final), 0, 0);
    }
  }
  @keyframes MoveRightInitial {
    to {
      transform: translate3d(105%, 0, 0);
    }
  }
  @keyframes MoveRightEnd {
    from {
      transform: translate3d(-100%, 0, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes MoveScaleUpInitial {
    to {
      transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
      opacity: 0;
    }
  }
  @keyframes MoveScaleUpEnd {
    from {
      transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes slotMachine {
    50% {
      transform: translate3d(0, 100%, 0);
      opacity: 0;
    }
    51% {
      transform: translate3d(0, -100%, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes rotateIt {
    to {
      transform: rotate(-360deg);
    }
  }
  /* Made with the help of Amelia Bellamy-Royds' article "Perfecting Paths for <textPath>": 
https://oreillymedia.github.io/Using_SVG/extras/ch07-textpaths.html */
  @keyframes blinkHide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }
  @keyframes blinkShow {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
  }

