.grid-reveal-custom article,
.grid-reveal-custom aside,
.grid-reveal-custom details,
.grid-reveal-custom figcaption,
.grid-reveal-custom figure,
.grid-reveal-custom footer,
.grid-reveal-custom header,
.grid-reveal-custom hgroup,
.grid-reveal-custom main,
.grid-reveal-custom nav,
.grid-reveal-custom section,
.grid-reveal-custom summary {
    display: block;
	height: 140vh;
}

.grid-reveal-custom audio,
.grid-reveal-custom canvas,
.grid-reveal-custom video {
    display: inline-block;
}

.grid-reveal-custom audio:not([controls]) {
    display: none;
    height: 0;
}

.grid-reveal-custom [hidden] {
    display: none;
}

.grid-reveal-custom html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.grid-reveal-custom body {
    margin: 0;
}

.grid-reveal-custom a:focus {
    outline: thin dotted;
}

.grid-reveal-custom a:active,
.grid-reveal-custom a:hover {
    outline: 0;
}

.grid-reveal-custom h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.grid-reveal-custom abbr[title] {
    border-bottom: 1px dotted;
}

.grid-reveal-custom b,
.grid-reveal-custom strong {
    font-weight: bold;
}

.grid-reveal-custom dfn {
    font-style: italic;
}

.grid-reveal-custom hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

.grid-reveal-custom mark {
    background: #ff0;
    color: #000;
}

.grid-reveal-custom code,
.grid-reveal-custom kbd,
.grid-reveal-custom pre,
.grid-reveal-custom samp {
    font-family: monospace, serif;
    font-size: 1em;
}

.grid-reveal-custom pre {
    white-space: pre-wrap;
}

.grid-reveal-custom q {
    quotes: "\201C" "\201D" "\2018" "\2019";
}

.grid-reveal-custom small {
    font-size: 80%;
}

.grid-reveal-custom sub,
.grid-reveal-custom sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.grid-reveal-custom sup {
    top: -0.5em;
}

.grid-reveal-custom sub {
    bottom: -0.25em;
}

.grid-reveal-custom img {
    border: 0;
}

.grid-reveal-custom svg:not(:root) {
    overflow: hidden;
}

.grid-reveal-custom figure {
    margin: 0;
}

.grid-reveal-custom fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

.grid-reveal-custom legend {
    border: 0;
    padding: 0;
}

.grid-reveal-custom button,
.grid-reveal-custom input,
.grid-reveal-custom select,
.grid-reveal-custom textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

.grid-reveal-custom button,
.grid-reveal-custom input {
    line-height: normal;
}

.grid-reveal-custom button,
.grid-reveal-custom select {
    text-transform: none;
}

.grid-reveal-custom button,
.grid-reveal-custom html input[type="button"],
.grid-reveal-custom input[type="reset"],
.grid-reveal-custom input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

.grid-reveal-custom button[disabled],
.grid-reveal-custom html input[disabled] {
    cursor: default;
}

.grid-reveal-custom input[type="checkbox"],
.grid-reveal-custom input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

.grid-reveal-custom input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.grid-reveal-custom input[type="search"]::-webkit-search-cancel-button,
.grid-reveal-custom input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.grid-reveal-custom button::-moz-focus-inner,
.grid-reveal-custom input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.grid-reveal-custom textarea {
    overflow: auto;
    vertical-align: top;
}

.grid-reveal-custom table {
    border-collapse: collapse;
    border-spacing: 0;
}

.grid-reveal-custom *,
.grid-reveal-custom *::after,
.grid-reveal-custom *::before {
    box-sizing: border-box;
}

.grid-reveal-custom :root {
    font-size: 16px;
}


body.grid-reveal-custom {
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #5a45a0;
	--color-link-hover: #fff;
	--color-bg-content: #5b6598;
	--color-title: #fff;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: linotype-didot, Didot, "Didot LT STD", "Bodoni MT", "ltc-bodoni-175", "Hoefler Text", Garamond, "Times New Roman", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.grid-reveal-custom {
	.hidden {
		pointer-events: none;
		visibility: hidden;
		position: fixed;
		z-index: -1;
	}

	.cursor, .cursor__inner {
		z-index: 9999;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		mix-blend-mode: difference;
	}

	.cursor__inner--circle {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 3px solid #de448d;
	}

	.cursor__inner--cross {
		width: 30px;
		height: 30px;
	}

	.cursor__inner--cross::before,
	.cursor__inner--cross::after {
		position: absolute;
		top: 0;
		content: '';
		width: 2px;
		height: 100%;
		background: #A21020;
		left: calc(50% - 1px);
		transform: rotate(45deg);
	}

	.cursor__inner--cross::after {
		transform: rotate(-45deg);
	}

	.cursor__inner--text {
		color: black; /* Cambia el color del texto a negro */
		font-weight: bold; /* Aplica un grosor de fuente más intenso */
		font-size: 16px; /* Ajusta el tamaño de la fuente según tu preferencia */
		white-space: nowrap;
		left: 30px;
		margin-top: 1px;
		color: #9A9A9A;
	}

	/* Page Loader */
	.js .loading::before {
		content: '';
		position: fixed;
		z-index: 100000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color-bg);
	}

	.js .loading::after {
		content: '';
		position: fixed;
		z-index: 100000;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		pointer-events: none;
		border-radius: 50%;
		opacity: 0.4;
		background: var(--color-link);
		animation: loaderAnim 0.7s linear infinite alternate forwards;
	}

	@keyframes loaderAnim {
		to {
			opacity: 1;
			transform: scale3d(0.5,0.5,1);
		}
	}

	a {
		text-decoration: none;
		color: var(--color-link);
		outline: none;
	}

	a:hover,
	a:focus {
		color: var(--color-link-hover);
		outline: none;
	}

	.frame {
		padding: 3rem 5vw;
		text-align: center;
		position: fixed;
		z-index: 1000;
		width: 100%;
		height: 140vh;
		text-transform: lowercase;
	}

	.demo-4 .frame {
		mix-blend-mode: color-dodge;
	}

	.frame__title-wrap {
		text-transform: capitalize;
	}

	.frame__title {
		font-size: 1rem;
		margin: 0 0 1rem;
	}

	.frame__links {
		display: inline;
	}

	.frame__links a:not(:first-child)::before {
		content: '|';
		margin: 0 1rem 0 0;
	}

	.frame__nav a,
	.frame__links a:not(:last-child),
	.frame__demos span, .frame__demos a:not(:last-child) {
		margin-right: 1rem;
	}

	.frame__main {
		font-size: 1.5rem;
		margin: 0;
	}

	.frame__nav,
	.frame__main,
	.frame__version {
		display: none;
	}

	.frame__demos {
		margin: 1rem 0;
	}

	.frame__demo--current,
	.frame__demo--current:hover {
		color: var(--color-text);
	}

	.content {
		opacity: 1;
  		background-color: #1e2022;
		padding-top: 10px !important;
		padding-right: 100px !important;
		padding-left: 100px !important;
		position: absolute;
		height: 140vh;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-position: 50% 30%;
		background-size: cover;
		background-blend-mode: soft-light;
		cursor: pointer;
	}

	.content__title {
		font-size: 10vw;
		will-change: transform;
		mix-blend-mode: overlay;
		text-align: center;
		color: var(--color-title);
		pointer-events: none;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none; 
		user-select: none; 
	}

	.grid-wrap--hidden {
		pointer-events: none;
	}

	.grid {
		display: grid;
		width: 103%;
		height: 140vw;
		--cell-number: 12;
		--cell-size: 9.222vw;
		grid-template-columns: repeat(auto-fill, var(--cell-size));
		will-change: transform;
	}

	@media screen and (orientation: portrait) {
		.grid {
			height: auto;
			--cell-number: 6;
			--cell-size: 16.6666vw;
		}
	}

	.grid__item {
		width: 100%;
		height: 100%;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		height: var(--cell-size);
		outline: 1px solid rgba(0,0,0,0.87);
	}

	.grid__item-inner {
		width: 100%;
		height: 100%;
		position: relative;
		background-position: 50% 50%;
		background-size: cover;
	}

	.demo-3 .grid__item-inner {
		background-blend-mode: color-dodge;
		background-color: #332d4a;
	}

	.demo-1 .grid__item-inner {
		filter: grayscale(1);
	}

	.grid__item-inner::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0);
		transition: background 0.1s ease;
	}

	.demo-4 .grid__item-inner::after {
		background: rgba(255,255,255,0.5);
	}

	.grid__item-inner:hover::after {
		background: rgba(255,255,255,0.5);
	}

	.demo-4 .grid__item-inner:hover::after {
		background: rgba(255,255,255,0.5);
	}

	@media screen and (min-width: 53em) {
		body {
			overflow: hidden;
		}
		.frame {
			position: fixed;
			text-align: left;
			z-index: 10000;
			top: 0;
			left: 0;
			display: grid;
			align-content: space-between;
			width: 100%;
			max-width: none;
			height: 100vh;
			padding: 2rem 2rem 2.5rem;
			pointer-events: none;
			grid-template-columns: 40% 30% 30%;
			grid-template-rows: auto auto auto;
			grid-template-areas: 'nav demos main'
								'... ... ...'
								'title ... version';
		}
		.frame__title-wrap {
			grid-area: title;
			display: flex;
			grid-column: span 2;
			pointer-events: auto;
		}
		.frame__title {
			margin: 0 2rem 0 0;
		}
		.frame__main {
			display: block;
			justify-self: end;
		}
		.frame__main::before {
			content: '\2015';
		}
		.frame__nav {
			display: block;
			grid-area: nav;
			align-self: center;
			pointer-events: auto;
		}
		.frame__version {
			display: none;
			grid-area: version;
			justify-self: end;
		}
		.frame__demos {
			margin: 0;
			grid-area: demos;
			justify-self: start;
			align-self: center;
			pointer-events: auto;
		}
		.frame a {
			pointer-events: auto;
		}
		.grid-wrap {
			height: 140vh;
			overflow: hidden;
		}
	}

	.customStyle{
		padding: 0rem 0 !important;
	}

	.fullscreenCustom{
		top: 5px !important;
		margin-bottom: 5px !important;
	}
	.grid__item {
		position: relative !important;
	}
	  
	.grid__item-text {
		display: none;
		position: absolute !important;
		bottom: 10px !important; /* Ajusta esta propiedad para la posición vertical */
		left: 10px !important; /* Ajusta esta propiedad para la posición horizontal */
		background-color: rgba(0, 0, 0, 0.7) !important;
		color: white !important;
		padding: 10px !important;
	}
	.grid__item-content{
		padding-top: 20px;
		width: 100%;
		height: 100%;
		
		.video-container{
			width: 100%;
			height: 315px !important;
			margin-left: auto;
    			margin-right: auto;
				width: 560px !important;
				opacity: 1; /* Ajusta la opacidad según tus preferencias */
				background-color: rgba(0, 0, 0, 1);

			.video{
				background-color: rgba(0, 0, 0, 1);
				margin-left: auto;
    			margin-right: auto;
				width: 560px !important;
				height: 315px !important;
				opacity: 1; /* Ajusta la opacidad según tus preferencias */
				pointer-events:all; 
			}
		}
	}
	.description{
		width: 100%;
		margin-top: 20px;
		padding-left: 100px;
		padding-right: 100px;
		font-family:poppins,sans-serif;
		display:block;
		text-transform:uppercase;
		font-size:14px;
		font-weight:700;
		letter-spacing:.5px;
	}
	p{
		width: 100%;
		padding-left: 100px;
		padding-right: 100px;
		font-family:poppins,sans-serif;

		display:block;
		text-transform:uppercase;
		font-size:14px;
		font-weight:700;
		letter-spacing:.7px;
	}
	.content__current__item{
		width: 100%;
		height: 100%;
		opacity: 1; /* Ajusta la opacidad según tus preferencias */

	}
	.text-end{
		text-align:right !important
	}
	.overlay-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #1e2022;
		opacity: 0.7; /* Ajusta la opacidad según tus preferencias */
	  }
	  .text-primary-custom{
		color: white;
	  }
}

// Ocultar en pantallas pequeñas (menos de 576px)
@media (max-width: 575.98px) {
	.prensa-custom-grid {
	  display: none;
	}
	.prensa-custom-grid-mobile {
		display: block; // O el valor que desees para mostrar
		// Estilos para el contenedor del grid
		.image-grid {
		  display: grid;
		  grid-template-columns: repeat(2, 1fr);
		  gap: 10px;
		  max-width: 800px;
		  margin: 0 auto;
		  padding: 20px;
		  background-color: #fff;
		  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
		}
		
		.image-item {
		  width: 100%;
		  height: auto;
		  border-radius: 5px;
		  overflow: hidden;
		  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
		}
		
		.image-item img {
		  width: 100%;
		  height: auto;
		}
	  }
  }
  
  // Ocultar en pantallas grandes (992px en adelante)
  @media (min-width: 992px) {
	.prensa-custom-grid-mobile {
	  display: none;
	}
  }




