@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html.convenios-custom,
body.convenios-custom {
    position: relative;
    height: 100%;
}

body.convenios-custom {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.convenios-custom{
    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

    }

    .sections {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ari_pods,
    .beats,
    .apple_watch,
    .galaxy_buds {
        width: 100%;
        height: 100vh;
        padding: 0 80px;
        @include media-breakpoint-down(xs) {
            padding: 0px;

        }
    }

    .section_container {
        gap: 100px;
        width: 1170px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;



        @include media-breakpoint-down(xs) {
            display: flex !important;
            flex-direction: column !important;
            gap: 100px !important; 
            
            width: 100% !important;

        }
    }

    .ari_pods {
        background-color: #1C1C1C;
    }

    .ari_pods {
        background-color: #FA9C6A;
    }

    .beats {
        background-color: #00B2EF
    }

    .apple_watch {
        background-color: #23135E;
    }

    .galaxy_buds {
        background-color: #EA1D25;
    }

    .image {
        width: 50%;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .image > img {
        width: 100%;
        @include media-breakpoint-down(xs) {
            width: 80%;
        }
    }

    .text {
        width: 50%;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        text-align: left;
        @include media-breakpoint-down(xs) {
            width: 100%;
            padding-left: 20px !important;
            padding-right: 20px !important;
            text-align: justify;
        }

    }

    .text > .title {
        font-size: 82px; 
        font-weight: 100;
        margin-bottom: 50px;
        line-height: .9;
        color: #fff;
        @include media-breakpoint-down(xs) {
            font-size: 50px; 

        }
        
    }
        

    .ari_pods > .section_container > .text {
        color: #fff;
    }

    .text > .description {
        font-size: 18px;
        font-weight: 100;
    }

    .beats > .section_container > .image {
        order: 2;
        width: 500px;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    .beats > .text {
        color: #000;
    }

    .galaxy_buds > .section_container > .text > .title {
        font-size: 45px;
    }

    .galaxy_buds > .section_container > .text > .title > span {
        font-weight: 500;
    }

    .galaxy_buds > .section_container > .text {
        color: #fff;
    }

    .swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, 
    .swiper-container-vertical>.swiper-pagination-progressbar {
        position: absolute;
        left: 95%;
        top: 25%;
        height: 50%;
    }

    .white-text{
        color: white !important;
        font-weight: 500 !important;
    }

    .black-text{
        color: black !important;
        font-weight: 500 !important;
    }
}

.vertical-swiper-custom{
    .ml-100{
        @include media-breakpoint-up(md){
            //line-height: 100px;
            margin-left: 100px !important;

        }
    }
}