#wrapper-preview-sucursales {
  $color-overlay: #000;
  $color-border: #a0988a;
  $color-text: #000;
  $color-text-alt: #fff;
  $color-column-title: #727170;
  $color-link-alt: #fff;
  $color-link-hover-alt: #a17445;
  $font-family_2: kudryashev-d-excontrast-sans, sans-serif;
  



  .container {
    &.content--hidden {
      pointer-events: none;
      
    }

    #portfolio{
      .portfolio-item{
        .portfolio-item-wrap{
          .item__img{
            img{
              height: 375px;
            }
          }
        }
      }
    }
    // grid-area: content;

    // .item {
    //   margin-bottom: 5rem;
    //   display: grid;
    //   grid-template-columns: 100%;
    //   grid-template-rows: auto auto 1fr auto;

    //   .item__title {
    //     font-family: $font-family_2;
    //     font-weight: 300;
    //     font-size: 2rem;
    //     margin-bottom: 0.5rem;
    //   }
    //   .item__img {
    //     position: relative;
    //     overflow: hidden;
    //     width: 100%;
    //     aspect-ratio: 500/333;
    //   }
    //   .item__img-inner {
    //     background-position: 50% 45%;
    //     background-size: cover;
    //     width: 100%;
    //     height: 100%;
    //   }
    //   .item__desc {
    //     margin-top: 2.5rem;
    //     line-height: 1.1;
    //   }
    //   .item__link {
    //     cursor: pointer;
    //     text-transform: lowercase;
    //     width: 100%;
    //     padding: 1rem;
    //     color: $color-text;
    //     border: 1px solid $color-border;
    //     border-radius: 2rem;
    //     text-align: center;
    //     &:hover {
    //       background: $color-text;
    //       border-color: $color-text;
    //       color: $color-text-alt;
    //     }
    //   }
    // }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    pointer-events: none;
    grid-template-rows: repeat(2, 1fr);
    z-index: 99;

    .overlay__row {
      background: $color-overlay;
      transform: scaleY(0);
      will-change: transform;
      &:first-child {
        transform-origin: 50% 0%;
      }
      &:last-child {
        transform-origin: 50% 100%;
      }
    }
  }
  .previews {
    // position: relative;
    // grid-area: content;
    pointer-events: none;

    .preview {
      display: grid;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      color: $color-text-alt;
      z-index: 9999;
    }
    .preview--current {
      opacity: 1;
      pointer-events: auto;
    }

    .preview__img {
       //comience en la columna 1 de la fila 1 y abarque 2 filas y 3 columnas:
      grid-area: 1 / 1 / 1 / -1;
      width: 100%;
      min-height: 200px;
      overflow: hidden;
      position: relative;
      transform: translateY(-101%);
      pointer-events: none;
      will-change: transform;
      @include media-breakpoint-down(md)
      {
        grid-area: 1 / 1 / 1 / 5;
      }
    }
    .preview__img-inner {
      background-position: 50% 35%;
      background-size: cover;
      background-color: #000;
      width: 100%;
      height: 100%;
      transform: translateY(101%);
      will-change: transform;
    }
    .preview__title {
      margin: 2rem 0;
      line-height: 0.7;
      font-size: clamp(2rem, 18vw, 15rem);
      white-space: nowrap;
      font-family: $font-family_2;
      font-weight: 300;
      justify-self: center;
      align-self: center;
      grid-area: 1 / 1 / 2 / -1;
      color: $color-text-alt;
      text-align: center;
      span {
        line-height: 0.5;
        padding-top: 7%;

      }
      @include media-breakpoint-down(md)
      {
        grid-area: 1 / 1 / 2 / 5;
      }
    }
    .preview__column {
      height: 100px;
      p {
        
        opacity: 0;
        color: $white;
        
       
      }
      > span {
        display: block;
      }
      &:not(.preview__column--start) {
        grid-row: 2 / span 2;
        @include media-breakpoint-down(md)
      {
        grid-row: 3 / span 2;
      }
      }
    }
    .preview__column-title {
      font-weight: 300;
      margin-top: 0;
      font-size: 1rem;
      &:not(.preview__column-title--main) {
        color: $color-column-title;
      }
      
    }
    .preview__column--start {
      grid-area: 2 / 1 / 3 / 3;
    }
    .preview__back {
      margin: 2rem auto;
      stroke: $color-link-alt;
      cursor: pointer;
      fill: none;
      grid-area: 6/1/6/2;
      justify-self: start;
      align-self: end;
      &:hover {
        stroke: $color-link-hover-alt;
      }
    }
    .oh {
      position: relative;
      overflow: hidden;
    }
    .oh__inner {
      will-change: transform;
      display: inline-block;
    }
    .line {
      transform-origin: 0 50%;
      white-space: nowrap;
      will-change: transform;
      margin: 0px;
      border-top: none;
    }
    
    .unbutton {
      background: none;
      border: 0;
      padding: 0;
      margin: 0;
      font: inherit;
      &:focus {
        outline: none;
      }
      @include media-breakpoint-down(md)
      {
        display: none;
      }
    }
    .close-preview {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      padding: 0;
      margin: 0;
      background: transparent;
      
      &:focus {
        outline: none;
      }
    }
  }

  @media screen and (min-width: 53em) {
    // .content {
    //   max-width: none;
    //   display: grid;
    //   grid-template-columns: repeat(3, 1fr);
    //   grid-template-rows: 100%;
    //   grid-column-gap: 5vw;
    // }
    // .item {
    //   margin-bottom: 0;
    // }
    // .item__title {
    //   font-size: clamp(1.25rem, 3vw, 2rem);
    // }
    .preview {
      display: grid;
      grid-column-gap: 13vw;
      grid-row-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr auto auto;
    }
    .preview__title {
      margin: 0;
    }
    .preview__back {
      margin: 0;
    }
  }
}
