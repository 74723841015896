/* ----------------------------------------------------------------
Topbar
-----------------------------------------------------------------*/
#topbar {
    position: relative;
    z-index: 200;
    background-color: $white;

    .top-menu {
        list-style: inside none none;
        margin: 0;
        padding: 0;
        float: left;
        

        &>li {
            display: inline-block;
            height: 40px;
            line-height: 40px;

            &>a {
                color: $body-color;
                display: block;
                font-size: 12px;
                font-weight: 500;
                height: 40px;
                margin-right: 16px;
            }
        }
    }

    .top-links {
        position: relative;
    }

    // Social Icons
    .social-icons {
        float: right;
        height: 100%;
        overflow: hidden;

        & li,
        li a {
            float: left;
            list-style: outside none none;
        }

        & li a {
            border-radius: 0px;
            font-size: 15px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 35px;
            overflow: hidden;
            margin: 0;
        }
    }

    

    /*colored*/
    &.topbar-colored,
    &.topbar-colored.dark {
        background-color: $theme;
    }
}

// Top bar dropdowns
.topbar-dropdown {
    color: #747474;
    float: left;
    font-size: 13px;
    font-weight: 400;
    position: relative;

    .title {
        border-left: 1px solid $light;
        padding: 0 20px;
        cursor: pointer;
        line-height: 40px;

        a {
            color: #747474;

            &:hover {
                text-decoration: underline;
            }
        }

        b {
            font-weight: 600;
        }

        .fa {
            margin-left: 7px;
            position: relative;
            top: -1px;

            &:first-child {
                margin-left: 0;
                margin-right: 7px;
            }
        }
    }

    &:first-child .title {
        border-color: transparent;
        padding-left: 0;
    }

    .title img,
    .list-entry img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        position: relative;
        top: -1px;
    }

    .dropdown-list {
        position: absolute;
        width: auto;
        left: 0px;
        top: auto;
        border-radius: 3px;
        background-color: $white;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        border: solid 1px #eee;
        padding: 0px 16px;
        z-index: 1;
        text-align: left;
        opacity: 0;
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        -webkit-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        transform-origin: 50% 0% 0px;
        -moz-transform-origin: 50% 0% 0px;
        -webkit-transform-origin: 50% 0% 0px;
        -ms-transform-origin: 50% 0% 0px;
        z-index: 1;
        visibility: hidden;
    }

    &:hover .dropdown-list,
    &:hover .topbar-form {
        opacity: 1;
        transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        visibility: visible;
    }

    .list-entry {
        line-height: 30px;
        white-space: nowrap;
        display: block;
        color: #747474;
        padding: 2px 0;
        border-top: 1px #f0f0f0 solid;

        &:hover {
            color: #222;
        }

        &:first-child {
            border-top: none;
        }
    }

    // Topbar Form
    .topbar-form {
        position: absolute;
        width: 240px;
        padding: 20px 20px 10px 20px;
        right: auto;
        top: 40px;
        border-radius: 3px;
        background-color: $white;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        border: solid 1px #eee;
        z-index: 1;
        text-align: left;
        opacity: 0;
        -moz-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        -webkit-transition: all 300ms ease-out;
        transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        transform-origin: 50% 0% 0px;
        -moz-transform-origin: 50% 0% 0px;
        -webkit-transform-origin: 50% 0% 0px;
        -ms-transform-origin: 50% 0% 0px;
        visibility: hidden;
    }

    input {
        margin-bottom: 2px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .checkbox {
        display: inline-block;
        margin-bottom: 0;
        margin-top: 0;
        vertical-align: middle;
        margin-top: -12px;
    }

    &.float-right .title {
        padding: 0 0px 0 20px !important;
    }

    .dropdown-invert.topbar-form {
        left: auto;
        right: -20px;
    }
}

// topbar fullwidth 
#topbar.topbar-fullwidth>.container {
    max-width: 100%;
    padding: 0 30px;
}

// dropdown topbar transparent
#topbar {
    border-bottom: 1px solid $light;

    &.topbar-dark,
    &.dark {
        .phone{
            font-size: 1.3em;
            color: $theme!important;
            margin-top: 5px;
            font-weight: 700;
        }
        .top-menu>li>a,
        .social-icons li a {
            color: $white ;
            
        }

        background-color: #252525;
        border-color: rgba(231, 231, 231, .25);
    }

    .topbar-light {

        .top-menu>li>a,
        .social-icons li a {
            color: #111 !important;
        }
    }
}

// topbar transparent 
.topbar-transparent {
    margin-top: -8px;

    &.topbar-dark .topbar-dropdown .title a,
    &.topbar-dark .topbar-dropdown {
        color: $white;
    }

    .topbar-dropdown {
        .title {
            border-color: rgba(231, 231, 231, .25);
        }

        &:first-child .title {
            border-color: transparent;
        }
    }
}

#topbar {
    &.topbar-dark.topbar-colored {
        border-bottom: 0;
    }

    &.dark {
        border-bottom: 1px solid rgba(231, 231, 231, .05);

        &.topbar-transparent {
            background-color: transparent;
            border-bottom: 1px solid rgba(144, 144, 144, 0.25);
        }
    }
}

#topbar~ {
    #header {
        &[data-transparent="true"] {

            +#slider,
            +.fullscreen,
            +.halfscreen,
            +#google-map {
                top: -(40 + $header-height);
                margin-bottom: -50px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 0px;
                    
                  }
            }
        }
    }
}

//TopBar - Responsive
@include media-breakpoint-down(lg) {
    #topbar {
        &.topbar-transparent.dark {
            background-color: $white !important;

            .top-menu>li>a,
            .social-icons li a {
                color: $body-color !important;
            }
        }
    }
}