/*--------------------------------------------------------
     Content
---------------------------------------------------------*/
#page-content:not(.no-sidebar) {
    padding: 40px 0 40px 0;

    &.sidebar-left {

        >.container,
        >.container-wide,
        >.container-fluid {
            >.row {
                flex-direction: row-reverse;
            }
        }
    }

    &.sidebar-right {

        >.container,
        >.container-wide,
        >.container-fluid {
            >.row {
                flex-direction: row;
            }
        }
    }

    &.sidebar-right {

        >.container,
        >.container-wide,
        >.container-fluid {
            >.row {
                flex-direction: row;
            }
        }
    }

    &.sidebar-both {

        >.container,
        >.container-wide,
        >.container-fluid {
            >.row {
                flex-direction: row;
            }
        }
    }
}

#content {
    overflow: hidden;
    padding: 60px 0;
    position: relative;

    .container,
    .container-wide,
    .container-fluid {
        position: relative;
    }
}

.page-content-shadow {
    box-shadow: 0 82px 53px 10px rgba(0, 0, 0, 0.3);
}

// Content
.content {
    padding-right: 25px;
    margin-bottom: 26px;
}

// Sidebar
.sidebar {
    padding-left: -25px;

    .content {
        padding-right: 25px;
        padding-left: 15px;
    }

    .sidebar {
        padding-left: 25px;
        padding-right: 15px;
    }

    &.sidebar-modern {
        padding-left: 15px;
        padding-right: 15px;

        &:before {
            background-color: $sidebar-background;
            width: 100%;
            content: "";
            position: absolute;
            z-index: -10;
            bottom: -10px;
            left: 0;
            right: 0;
            top: -20px;
            border-radius: 4px;
        }
    }
}

.sidebar-left {
    .content {
        padding-left: 25px;
        padding-right: 15px;
    }

    .sidebar {
        padding-right: 25px;
        padding-left: 15px;

        &.sidebar-modern {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.sidebar-both {
    .sidebar {
        padding-left: 15px;
        padding-right: 25px;

        &.sidebar-modern {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .content {
        padding-right: 25px;
        padding-left: 25px;

        +.sidebar {
            padding-left: 25px;
            border-right-width: 0;
            padding-right: 15px;

            &.sidebar-modern {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #page-content:not(.no-sidebar) {
        &.sidebar-both {

            >.container,
            >.container-wide,
            >.container-fluid {
                >.row {
                    .content {
                        order: -1;
                    }
                }
            }
        }
    }

    .content,
    .sidebar {
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
    }

    .sidebar-right .sidebar,
    .sidebar-right .content,
    .sidebar-both .content,
    .sidebar-both .content+.sidebar {
        padding-right: 15px;
        padding-left: 15px;
    }

    .sidebar-both .sidebar,
    .sidebar-right .sidebar,
    .sidebar {
        &:before {
            right: 0;
            border-right-width: 0;
            border-left-width: 0;
            left: 0;
        }

        .widgets {
            float: left;
        }
    }

    .sidebar-both {
        .content {
            +.sidebar {
                border-right-width: 0;

                &:before {
                    border-right-width: 0;
                    border-left-width: 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #page-content {
        padding: 20px 0 20px 0;
    }
}