#section-waves{

	/* Animation */
	@keyframes move-forever {
		0% {
			transform: translate3d(-90px,0,0);
		}
		100% {
			transform: translate3d(85px,0,0);
		}
	}



	.header {
		position: relative;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		z-index: 1;
		background: linear-gradient(90deg, rgba(255,108,14,1) 0%, rgba(239,54,54,1) 100%);
		
	}

	.inner-header {
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	.flex {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.waves {
		position: relative;
		width: 100vw;
		height: 25vh;
		margin-top: 10%;
		min-height: 100px;
		max-height: 150px;
	}
	.waves-1 {
		position: relative;
		width: 100vw;
		height: 25vh;
		min-height: 100px;
		max-height: 150px;
		margin-top: 0;
		transform: rotate(180deg);

	}
	.content {
		position: relative;
		height: 100%;
		text-align: center;
		
	}
	.parallax {
		>use {
			animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
			&:nth-child(1) {
				animation-delay: -2s;
				animation-duration: 7s;
			}
			&:nth-child(2) {
				animation-delay: -3s;
				animation-duration: 10s;
			}
			&:nth-child(3) {
				animation-delay: -4s;
				animation-duration: 13s;
			}
			&:nth-child(4) {
				animation-delay: -5s;
				animation-duration: 20s;
			}
		}
		.img-loaded {
			opacity: 0.3;
		}
	}
	@media (max-width: 768px) {
		
		.waves {
			margin-top: 40%;
		}
		.waves-1{
			margin-top: -3%;
		}
		.content {
			height: 25vh;
		}
		
	}

	.img-loaded {
		opacity: 0.1;
	}

}