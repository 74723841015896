/* ----------------------------------------------------------------------
	Buttons Hover
-------------------------------------------------------------------------*/
// TODO: Laura integrar Variables a varibles Globales
$color_7: #d73f08;
$font-family_2: inherit;
$font-family_3: aktiv-grotesk-extended, sans-serif;
$font-family_4: input-mono-narrow, monospace;
$font-family_5: signo, sans-serif;
$font-family_6: freight-display-pro, serif;
$font-family_7: bely-display, sans-serif;
$font-family_8: obvia, sans-serif;
$font-family_9: chapman-extended, sans-serif;



.button-animation {
  pointer-events: auto;
  cursor: pointer;
  background: #e7e7e7;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: $font-family_2;
  font-size: inherit;
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &button {
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 2px solid #443ffc;
      outline-offset: 3px;
    }
  }


  &.button--pan {
    font-family: $font-family_3;
    font-weight: 700;
    border: 2px solid #000;
    border-radius: 3rem;
    overflow: hidden;
    color: $white;
    span {
      position: relative;
      mix-blend-mode: difference;
    }
    &::before {
      content: "";
      background: #000;
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    }
    &:hover {
      &::before {
        transform: translate3d(0, -100%, 0);
      }
    }
  }
  &.button--hyperion {
    font-family: $font-family_4;
    font-weight: 500;
    padding: 1rem 1.5rem;
    border: 1px solid #000;
    overflow: hidden;
    color: $white;
    span {
      display: block;
      position: relative;
    }
    > span {
      overflow: hidden;
      > span {
        overflow: hidden;
        mix-blend-mode: difference;
      }
    }
    &:hover {
      > span {
        > span {
          animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
        }
      }
      &::before {
        transform: scale3d(0, 1, 1);
        transform-origin: 0% 50%;
      }
    }
    &::before {
      content: "";
      background: #000;
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      transform-origin: 100% 50%;
    }
  }
  &.button--mimas {
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 0.85rem;
    border-radius: 0.5rem;
    overflow: hidden;
    color: $white;
    background: #e7e7e7;
    span {
      position: relative;
      mix-blend-mode: difference;
    }
    &::before {
      content: "";
      background: #000;
      width: 120%;
      left: -10%;
      transform: skew(30deg);
      transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
    }
    &:hover {
      &::before {
        transform: translate3d(100%, 0, 0);
      }
    }
  }
  &.button--atlas {
    font-family: $font-family_5;
    font-weight: 500;
    > span {
      display: inline-block;
    }
    &:hover {
      > span {
        opacity: 0;
      }
      .marquee__inner {
        animation-play-state: running;
        opacity: 1;
        transition-duration: 0.4s;
      }
    }
  }
  &.marquee {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    span {
      text-align: center;
      white-space: nowrap;
      font-style: italic;
      padding: 1.5rem 0.5rem;
    }
  }
  &.marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    --offset: 1rem;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marquee 1s linear infinite;
    animation-play-state: paused;
    opacity: 0;
  }
  &.button--kari {
    font-family: $font-family_6;
    font-weight: 900;
    font-size: 1.25rem;
    border-radius: 50%;
    border: 2px solid #000;
    > span {
      display: inline-block;
      transition: opacity 0.1s;
    }
    &:hover {
      > span {
        opacity: 0;
      }
      .marquee__inner {
        animation-play-state: running;
        opacity: 1;
        transition-duration: 0.6s;
      }
    }
    .marquee {
      transform: rotate(-2.75deg);
    }
  }
  &.button--pandora {
    background: #000;
    font-weight: 700;
    padding: 0;
    border-radius: 5px;
    span {
      display: block;
      background: #a7a7a7;
      padding: 1.5rem 2rem;
      border: 1px solid #000;
      border-radius: 5px;
      transform: translate3d(-4px, -4px, 0);
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    }
    &:hover {
      span {
        transform: translate3d(-8px, -8px, 0);
      }
    }
  }
  &.button--janus {
    font-family: $font-family_6;
    font-weight: 900;
    width: 175px;
    height: 120px;
    color: $white;
    background: none;
    &::before {
      content: "";
      background: #e6e6e6;
      -webkit-clip-path: path(
        "M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z"
      );
      clip-path: path(
        "M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z"
      );
      transition: clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55),
        -webkit-clip-path 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55),
        background 0.5s ease;
    }
    &:hover {
      &::before {
        background: #000;
        -webkit-clip-path: path(
          "M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z"
        );
        clip-path: path(
          "M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z"
        );
      }
      &::after {
        transform: translate3d(0, -5px, 0);
      }
      span {
        transform: translate3d(0, -10px, 0);
      }
    }
    &::after {
      content: "";
      height: 86%;
      width: 97%;
      top: 5%;
      border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%;
      border: 1px solid #000;
      transform: rotate(-20deg);
      z-index: -1;
      transition: transform 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
    }
    span {
      display: block;
      transition: transform 0.3s ease;
      mix-blend-mode: difference;
    }
  }
  &.button--anthe {
    font-family: $font-family_7;
    color: $white;
    background: none;
    &::before {
      content: "";
      background: #000;
      -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%);
      transition: clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1),
        -webkit-clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1);
    }
    &:hover {
      &::before {
        background: #000;
        -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
      }
      span {
        transform: translate3d(-10px, 0, 0);
      }
    }
    span {
      display: block;
      mix-blend-mode: difference;
      transition: transform 0.4s cubic-bezier(0.2, 1, 0.8, 1);
    }
  }
  &.button--pallene {
    font-family: $font-family_3;
    font-weight: 700;
    border-radius: 0.5rem;
    border: 2px solid #000;
    box-shadow: inset 0 0 0 0px #000;
    transition: border-radius 0.3s, box-shadow 0.3s, color 0.3s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
    &:hover {
      color: $color_7;
      border-radius: 50%;
      box-shadow: inset 0 0 0 40px #000;
      transition-delay: 0s, 0s, 0.2s;
    }
  }
  &.button--telesto {
    overflow: hidden;
    font-family: $font-family_8;
    font-weight: 800;
    font-style: italic;
    font-size: 1.15rem;
    color: $white;
    span {
      display: block;
      position: relative;
      z-index: 1;
    }
    > span {
      overflow: hidden;
      mix-blend-mode: difference;
    }
    &:hover {
      > span {
        > span {
          animation: MoveRightInitial 0.1s forwards,
            MoveRightEnd 0.3s forwards 0.2s;
        }
      }
      &::before {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }
      &::after {
        transform: translate3d(0, 0, 0);
        transition: transform 0.01s 0.3s cubic-bezier(0.7, 0, 0.2, 1);
      }
    }
    &::before {
      content: "";
      background: #000;
      width: 135%;
      -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
      clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
      transform: translate3d(-100%, 0, 0);
    }
    &::after {
      content: "";
      background: #000;
      width: 105%;
      transform: translate3d(100%, 0, 0);
      transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    }
  }
  &.button--calypso {
    overflow: hidden;
    font-family: $font-family_6;
    font-size: 1.15rem;
    border-radius: 0.85rem;
    color: $white;
    span {
      display: block;
      position: relative;
      mix-blend-mode: difference;
      z-index: 10;
    }
    &:hover {
      span {
        animation: MoveScaleUpInitial 0.3s forwards,
          MoveScaleUpEnd 0.3s forwards 0.3s;
      }
      &::before {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      }
      &::after {
        transform: translate3d(0, 0, 0);
        transition-duration: 0.05s;
        transition-delay: 0.4s;
        transition-timing-function: linear;
      }
    }
    &::before {
      content: "";
      background: #000;
      width: 120%;
      height: 0;
      padding-bottom: 120%;
      top: -110%;
      left: -10%;
      border-radius: 50%;
      transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
    }
    &::after {
      content: "";
      background: #000;
      transform: translate3d(0, -100%, 0);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
  }
  &.button--skoll {
    overflow: hidden;
    border-radius: 50%;
    color: $white;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0%;
    font-weight: 500;
    background: transparent;
    border: 2px solid #fff;
    top: -3px;
    span {
      display: block;
      position: relative;
    }
    > span {
      overflow: hidden;
      mix-blend-mode: normal;
      position: absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      > span {
        > span {
          animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
          color:#F7A16B;
        }
      }
      &::before {
        transform: translate3d(0, 100%, 0);
       
      }
    }
    &::before {
      content: "";
      background: transparent;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      border-radius: 50%;
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
      
    }
    &:hover{
      border:2px solid  #F7A16B;
    }
  }
  &.button--greip {
    overflow: hidden;
    color: $white;
    font-family: $font-family_4;
    font-weight: 500;
    padding: 1rem 2rem;
    span {
      display: block;
      position: relative;
    }
    > span {
      overflow: hidden;
      mix-blend-mode: difference;
    }
    &:hover {
      > span {
        > span {
          animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
        }
      }
      &::before {
        transition-duration: 0.3s;
        -webkit-clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%);
      }
    }
    &::before {
      content: "";
      background: #000;
      width: 100%;
      height: 100%;
      transform-origin: 50% 100%;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      transition: clip-path 0.2s, -webkit-clip-path 0.2s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.2, 1);
    }
  }
  &.button--dione {
    background: none;
    font-family: $font-family_8;
    font-weight: 500;
    font-style: italic;
    padding: 1.5rem 3rem;
    span {
      display: inline-block;
      position: relative;
      color: $white;
    }
    &::before {
      content: "";
      background: #000;
      transition: transform 0.3s cubic-bezier(0.2, 1, 0.7, 1);
    }
    &:hover {
      &::before {
        transform: scale3d(0.9, 0.8, 1);
      }
      &::after {
        transform: scale3d(1, 1, 1);
      }
    }
    &::after {
      content: "";
      border: 1px solid #000;
      transition: transform 0.3s cubic-bezier(0.2, 1, 0.7, 1);
      border-radius: 0px;
      transform: scale3d(0.85, 0.65, 1);
    }
  }
  &.button--helene {
    border-radius: 7px;
    border: 1px solid #000;
    font-family: $font-family_9;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    font-size: 0.85rem;
    padding: 0 3rem;
    height: 4rem;
    &::before {
      content: "";
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      background: rgba(0, 0, 0, 0.5);
      filter: blur(7px);
      border-radius: 7px;
      z-index: -1;
      transform: translate3d(0, 15px, 0);
      transition: transform 0.45s;
    }
    &:hover {
      &::before {
        transform: translate3d(0, 0, 0);
      }
      > span {
        > span {
          animation: slotMachine 0.15s ease-out forwards 3;
        }
      }
    }
    span {
      display: block;
    }
    > span {
      height: 100%;
      overflow: hidden;
      line-height: 4rem;
    }
  }
  &.button--rhea {
    font-family: $font-family_6;
    font-weight: 900;
    font-style: normal;
    width: 180px;
    height: 180px;
    color: $theme;
    background: none;
    &::before {
      content: "";
      z-index: -1;
      background: #e7e7e7;
      -webkit-clip-path: polygon(
        20% 30%,
        0 30%,
        0 50%,
        0 70%,
        20% 70%,
        50% 70%,
        80% 70%,
        100% 70%,
        100% 50%,
        100% 30%,
        80% 30%,
        50% 30%
      );
      clip-path: polygon(
        20% 30%,
        0 30%,
        0 50%,
        0 70%,
        20% 70%,
        50% 70%,
        80% 70%,
        100% 70%,
        100% 50%,
        100% 30%,
        80% 30%,
        50% 30%
      );
      transition: clip-path 0.4s cubic-bezier(0.3, 1, 0.2, 1),
        -webkit-clip-path 0.4s cubic-bezier(0.3, 1, 0.2, 1),
        transform 0.4s cubic-bezier(0.3, 1, 0.2, 1), background 0.4s ease;
    }
    &:hover {
      &::before {
        background: #000;
        transform: scale3d(0.7, 0.7, 1);
        -webkit-clip-path: polygon(
          30% 10%,
          10% 30%,
          30% 50%,
          10% 70%,
          30% 90%,
          50% 70%,
          70% 90%,
          90% 70%,
          70% 50%,
          90% 30%,
          70% 10%,
          50% 30%
        );
        clip-path: polygon(
          30% 10%,
          10% 30%,
          30% 50%,
          10% 70%,
          30% 90%,
          50% 70%,
          70% 90%,
          90% 70%,
          70% 50%,
          90% 30%,
          70% 10%,
          50% 30%
        );
      }
      span {
        transform: scale3d(0.1, 0.1, 1);
        opacity: 0;
        transition-delay: 0s, 0.35s;
      }
    }
    span {
      display: block;
      transition: transform 0.4s cubic-bezier(0.3, 1, 0.2, 1), opacity 0.05s;
    }
  }
  &.button--narvi {
    font-weight: bold;
    background: none;
    &::before {
      content: "";
      z-index: -1;
      background: #e7e7e7;
      -webkit-clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 70%,
        85% 70%,
        80% 70%,
        75% 70%,
        0 70%
      );
      clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 70%,
        85% 70%,
        80% 70%,
        75% 70%,
        0 70%
      );
      transition: clip-path 0.3s cubic-bezier(0.7, 0, 0.2, 1),
        -webkit-clip-path 0.3s cubic-bezier(0.7, 0, 0.2, 1), transform 0.3s ease;
    }
    &:hover {
      &::before {
        transform: translate3d(0, -10px, 0);
        -webkit-clip-path: polygon(
          0% 0%,
          100% 0%,
          100% 70%,
          85% 70%,
          86% 100%,
          75% 70%,
          0 70%
        );
        clip-path: polygon(
          0% 0%,
          100% 0%,
          100% 70%,
          85% 70%,
          86% 100%,
          75% 70%,
          0 70%
        );
      }
      > span {
        transform: translate3d(0, -1.111rem, 0);
        > span {
          animation: MoveUpInitial 0.15s forwards,
            MoveUpEnd 0.15s forwards 0.15s;
        }
      }
    }
    span {
      display: block;
      position: relative;
    }
    > span {
      transition: transform 0.3s;
      transform: translate3d(0, -0.556rem, 0);
      z-index: 1;
      overflow: hidden;
    }
  }
  &.button--hati {
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid;
    background: none;
    font-family: $font-family_3;
    font-weight: 900;
    font-style: italic;
    &:hover {
      background: none;
      &::before {
        opacity: 0.7;
      }
    }
    &::before {
      animation: rotateIt 10s linear infinite;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAUElEQVQoU3VPSQ4AMQiS/z/aBiMNNDNeXEBQoDL66dkG52/hzlmsSqMKrAVCoOXuxR3TFVSIIJmzsFvGOMlust3iivmQd/6+3/NlN4paIOEADPIUD/MQvy0AAAAASUVORK5CYII=);
      content: "";
      width: 300%;
      height: 300%;
      top: -100%;
      left: -100%;
      z-index: -1;
      opacity: 0;
      transform-origin: 50% 50%;
      transition: opacity 0.3s;
    }
    span {
      display: block;
      position: relative;
      z-index: 1;
    }
  }
  &.button--bestia {
    font-size: 1.15rem;
    color: $white;
    background: none;
    padding: 0; 
    margin-right: 15px;
    .button__bg {
      top: 21px;
      left: 0px;
      position: absolute;
      width: 65%;
      height: 26%;
      background: transparent;
      border: 2px solid #fff;
      border-radius: 0.85rem;
      overflow: hidden;
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      padding: 20px 35px 20px;
      @include media-breakpoint-down(md) {
        top: 0px;
        left: 0px;
      }
      &::before {
        content: "";
        position: absolute;
        background: #ffffff;
        width: 110%;
        height: 0;
        padding-bottom: 110%;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
      }
      &::after {
        content: "";
        position: absolute;
        background: #ffffff;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
    &:hover {
      .button__bg {
        transform: scale3d(1.2, 1.2, 1);
        &::before {
          transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
          transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
        }
        &::after {
          opacity: 1;
          transition-duration: 0.01s;
          transition-delay: 0.3s;
        }
      }
    }
    span {
      display: block;
      position: relative;
      margin-left: 12px;
      mix-blend-mode: normal;
      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }
      &:hover {
        color: #1e2022;
      }
    }
  }
  &.button--surtur {
    padding: 0;
    background: none;
    -webkit-clip-path: circle(40% at 50% 50%);
    clip-path: circle(40% at 50% 50%);
    &:focus-visible {
      background: #443ffc;
    }
    &:hover {
      .textcircle {
        animation: rotateIt 7s linear infinite;
      }
      .eye__lashes-up {
        animation: blinkHide 2s step-end infinite;
      }
      .eye__inner {
        animation: blinkHide 2s step-end infinite;
      }
      .eye__iris {
        animation: blinkHide 2s step-end infinite;
      }
      .eye__lashes-down {
        animation: blinkShow 2s step-end infinite;
      }
    }
  }
  &.textcircle {
    position: relative;
    display: block;
    width: 200px;
    text {
      font-size: 32px;
      text-transform: uppercase;
      fill: #000;
    }
    textPath {
      letter-spacing: 17px;
    }
  }
  &.eye {
    position: absolute;
    z-index: 2;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  &.eye__outer {
    stroke: #000;
    fill: none;
    stroke-width: 1.5px;
  }
  &.eye__inner {
    stroke: #000;
    fill: none;
    stroke-width: 1.5px;
  }
  &.eye__lashes-up {
    stroke: #000;
    fill: none;
    stroke-width: 1.5px;
  }
  &.eye__lashes-down {
    stroke: #000;
    fill: none;
    stroke-width: 1.5px;
    opacity: 0;
  }
  &.button--fenrir {
    background: none;
    padding: 0;
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 120px;
    height: 120px;
    font-family: $font-family_3;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.85rem;
    &:hover {
      .progress {
        transform: scale3d(1.2, 1.2, 1);
      }
      .progress__path {
        stroke-dashoffset: 0;
      }
    }
    &:focus-visible {
      .progress__circle {
        fill: rgba(252, 196, 63, 0.4);
      }
    }
  }
  &.progress {
    position: absolute;
    width: 80px;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
  &.progress__circle {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 1px;
  }
  &.progress__path {
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 1px;
    stroke: #a6a6a6;
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
}
