/* ----------------------------------------------------------------
    Search
-----------------------------------------------------------------*/
#search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $white;
    line-height: normal;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;

    #search-logo {
        top: -1px;
        left: 30px;
        opacity: 0;
        transition: opacity 0.5s;
        position: absolute;
        line-height: 80px;
        height: 80px;
        width: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .search-form {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        transition: opacity 0.5s, transform 0.5s;
        margin: 80px 0;

        .text-muted {
            opacity: .4;
        }

        .form-control {
            border-width: 0px 0px 2px 0px;
            border-radius: 0px;
            font-size: 34px;
            padding: 10px 0;
            border-color: $gray-500;
            font-weight: 700;
            margin-bottom: 20px;
            background-color: transparent;

            &:focus {
                background-color: transparent;
                outline: none;
                box-shadow:none;
            }

            &:-webkit-input-placeholder {
                color: $gray-500;
            }

            &:-moz-placeholder {
                color: $gray-500;
            }

            &:-ms-input-placeholder {
                color: $gray-500;
            }
        }
    }

    .btn-search-close {
        font-size: 2em;
        position: absolute;
        top: 20px;
        right: 30px;
        display: none;
        padding: 12px;
        line-height: 12px;
        background-color: $theme;
        border: 0;
        cursor: pointer;
        border-radius: $border-radius;
        color: $white;
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1);
        transition: opacity 0.5s, transform 0.5s;
    }

    .search-suggestion-wrapper {
        display: none;
    }
}

/* ----------------------------------------------------------------
    Search / Open state
-----------------------------------------------------------------*/
body.search-open {
    #topbar {
        z-index: 199;
    }

    #search {
        z-index: 201;
        pointer-events: auto;
        opacity: 1;

        #search-logo {
            display: block;
            opacity: 1;
        }

        .search-form {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }

        .btn-search-close {
            display: block;
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }

        .search-suggestion {
            opacity: 1;
            transform: translate3d(0, 0, 0);

            &:nth-child(2) {
                transition-delay: 0.1s;
            }

            &:nth-child(3) {
                transition-delay: 0.2s;
            }
        }
    }
}

/* ----------------------------------------------------------------
    Search Responsive
-----------------------------------------------------------------*/
@include media-breakpoint-down(lg) {
    #search {
        #search-logo {
            margin: 0 auto !important;
            max-width: 130px;
            left: auto;
        }

        .search-form {
            margin: 40px;

            .form-control {
                font-size: 24px;
                margin-bottom: 8px;
            }

            .text-muted {
                font-size: 10px;
            }
        }

        .btn-search-close {
            font-size: 1em;
            padding: 8px;
            line-height: 8px; 
        }
    }
}