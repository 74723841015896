
.prices-page-custom{
   .mt-100 { margin-top: 100px !important; } 
   .block-stop-bebes{
      height: 380px !important;

      @include media-breakpoint-down(lg){
         //line-height: 100px;
          height: 0px !important;
     }
     
   }
   .block-stop-ninos{
      height: 350px !important;

      @include media-breakpoint-down(lg){
         //line-height: 100px;
          height: 0px !important;
     }
   }
   .block-stop-adultos{
      height: 340px !important;

      @include media-breakpoint-down(lg){
         //line-height: 100px;
          height: 0px !important;
     }
   }

   .vertical-elements{
      @include media-breakpoint-down(lg){
         display: flex;
         flex-direction: column;
         margin-left: 0px;
      }
   }
   .ml-0{
      @include media-breakpoint-down(lg){
         margin-left: 0px !important;

      }
   }
   .ma{
      @include media-breakpoint-down(lg){
         margin-left: auto !important;
         margin-right: auto !important;
      }

   }
}
