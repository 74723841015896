//
// Utilities
//


//@import "utilities/background";
//@import "utilities/borders";
//@import "utilities/image";
//@import "utilities/sizing";
//@import "utilities/spacing";
//@import "utilities/shadows";
//@import "utilities/text";
//@import "utilities/zindex";
//@import "utilities/sizing";
@import "utilities/section";
//@import "utilities/color";
 
@import "utilities/hover-animation";

 

.rounded-circle { 
    min-width: 1rem;
    min-height: 1rem;
} 