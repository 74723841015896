
  
.promociones-custom{
    
    display: inline-flex;
    flex-direction: column; 
    gap: 300px;
    text-align: center;
    height: auto;
    @include media-breakpoint-down(576px){
        gap: 50px;

        height: auto;

    }
    

    .card{
        width: 80% !important;
        height: 300px !important;
        
        margin-left: auto;
        margin-right: auto; 
        border-radius: 50px;
        margin-top: 100px;
        margin-bottom: 100px;

        @include media-breakpoint-down(576px){
            //line-height: 100px;
            height: auto !important;
            border-radius: 15px;
            margin-top: 0px;
            margin-bottom: 0px;

    
        }
    }
    .rounded-image img {
        max-width: 100%;
        height: auto;
        border-radius: 50px;
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: center;
        @include media-breakpoint-down(576px){
            //line-height: 100px;
            border-radius: 15px;

    
        }
    }
}