.section-products {
height: 85vh;
width: 100vw;
/** With clip-path (It doesn't work on Firefox) **/
/*.wrap-clip {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #607D8B;
	-webkit-clip-path: polygon(0 25%, 0% 100%, 75% 100%);
	clip-path: polygon(0 25%, 0% 100%, 75% 100%);
	transition: all 0.8s ease-in-out;
}*/
@keyframes sonar {
	0% {
		transform: scale(.9);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.4);
	}
	50% {
		transform: scale(.9);
	}
	80% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}



.products  {
	margin: 0;
	padding: 0;
	height: 100%;
	overflow-x: hidden;
	min-height: 630px;
	width: 100%!important;
	margin: 0 auto;
	max-height: 720px;
    position: relative ;
	overflow: hidden;
	
}
.wrap {
	margin: 0 auto;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #e5e5e5 ;
	display: flex;
	align-items: center;
	justify-content: center;
}
.wrap-clip {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 630px 0 0 1120px;
	border-color: #e5e5e5 transparent transparent transparent;;
	margin-top: -1px;
	transition: all 0.8s ease-in-out;
}
.content {
	margin: 0 auto;
	position: relative;
	width: calc(100% - 140px);
	height: calc(100% - 100px);
	box-shadow: 0 0 12px rgba(0,0,0,.25);
	z-index: 100;
	.bar {
		position: absolute;
		top: 5px;
		right: 5px;
		height: calc(100% - 10px);
		width: 70px;
		background-color: rgba(0,0,0,.05);;
		z-index: 10;
		>* {
			display: block;
			text-align: center;
		}
		img {
			width: 48px;
			margin-bottom: 5px;
			transition: all .4s ease-in-out;
		}
		a {
			&:hover {
				img {
					transform: scale(-1,1);
					transition: all .4s ease-in-out;
				}
			}
		}
	}
	.top {
		position: absolute;
		top: 5px;
		left: 5px;
		width: calc(100% - 80px);
		height: 40px;
		z-index: 10;
	}
}
.menu {
	padding: 0 20px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	text-align: right;
	a {
		margin-right: 40px;
		font-weight: 600;
		font-size: 14px;
		text-decoration: none;
		color: #888;
	}
}
.product {
	margin-top: 15%;
	width: 550px;
	transform: rotate(-20deg);
	@include media-breakpoint-down(md) {
		width: 200px;
		margin-bottom: 115%;
	  }
	  
}
.image {
	margin-right: 50px;
	margin-left: 20px;
}
.info {
	margin-left: 20px;
	width: 550px;
	color:#333;
	p {
		text-align: justify;
		line-height: 1.5;
		font-size: 14px;
		color:#444;
	}
	button {
		margin-top: 25px;
		margin-bottom: 20px;
		width: 100%;
		i {
			font-size: 24px;
		}
	}
	h2 {
		margin: 10px 0;
		font-size: 32px;
		@include media-breakpoint-down(md) {
			font-size: 18px;
		}
	}
	h3 {
		margin: 10px 0;
	}
	@include media-breakpoint-down(md) {
		width: 260px;
		margin-top: 90%;
		margin-left: -80%;
	}
}
.item {
	.title {
		position: absolute;
		top: 80px;
		left: 30px;
		font-size: 100px;
		font-weight: 800;
		color:rgba(255, 255, 255);
		@include media-breakpoint-down(md) {
			font-size: 50px;
		  }
	}
	margin: 0;
	position: absolute;
	top: 10px;
	left: 10px;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	display: none;
	align-items: center;
	backface-visibility: hidden;
}
.logo {
	margin-left: 20px;
	width: 148px;
	float: left;
	opacity: 0.75;
}
.cart {
	position: relative;
	padding: 5px;
	margin-bottom: 15px;
	font-size: 24px;
	text-align: center;
	color:#777;
}
.products {
	
	width: 920px;
	height: 100%;
	position: relative;
	perspective: 1000px;
}
.products-wrap {
	position: absolute;
	align-items: center;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.8s ease-in-out;
	.item {
		&:nth-child(1) {
			transform: rotateY(0deg) translateZ(237px);
			display: flex;
		}
		&:nth-child(2) {
			transform: rotateY(120deg) translateZ(237px);
			display: flex;
		}
		&:nth-child(3) {
			transform: rotateY(240deg) translateZ(237px);
			display: flex;
		}
	}
}
.wrap[data-pos="0"] {
	.products-wrap {
		-webkit-transform: translateZ(-237px) rotateY(0deg);
		transform: translateZ(-237px) rotateY(0deg);
	}
	.wrap-clip {
		background-color: $aqua;
	}
	.menu {
		a {
			&:hover {
				color:#673AB7;
			}
		}
	}
}
.wrap[data-pos="1"] {
	.products-wrap {
		-webkit-transform: translateZ(-237px) rotateY(-120deg);
		transform: translateZ(-237px) rotateY(-120deg);
	}
	.wrap-clip {
		background-color: $theme;
	}
	.menu {
		a {
			&:hover {
				color:#F44336;
			}
		}
	}
}
.wrap[data-pos="2"] {
	.products-wrap {
		-webkit-transform: translateZ(-237px) rotateY(-240deg);
		transform: translateZ(-237px) rotateY(-240deg);
	}
	.wrap-clip {
		background-color: $azul;
	}
	.menu {
		a {
			&:hover {
				color:#2196F3;
			}
		}
	}
}
.btn {
	padding: 0;
	position: relative;
	display: block;
	overflow: hidden;
	border-width: 0;
	outline: none;
	border-radius: 2px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
	background-color: $theme;
	color:#ecf0f1;
	transition: background-color .3s;
	&:hover {
		background-color: $theme;
	}
	&:focus {
		background-color: $theme;
	}
	>* {
		position: relative;
	}
	span {
		display: block;
		padding: 8px 24px;
	}
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 0;
		padding-top: 0;
		border-radius: 100%;
		background-color: rgba(236, 240, 241, .3);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	&:active {
		&:before {
			width: 120%;
			padding-top: 120%;
			transition: width .2s ease-out, padding-top .2s ease-out;
		}
	}
}
.badge {
	padding: 3px 7px;
	position: absolute;
	right: 10px;
	top: 0;
	display: inline-block;
	background-color: $theme;
	border-radius: 50px;
	font-size: 11px;
	font-weight: 700;
	color:$white;
	&:before {
		content: '';
		position: absolute;
		top: -2px;
		left: -2px;
		width: 100%;
		height: 100%;
		border: dotted #F44336 2px;
		border-radius: 20px;
		opacity: 0;
	}
}
.cart.add {
	.badge {
		animation: pulse 1.5s 1;
		&:before {
			animation: sonar 1.5s 1;
		}
	}
}
.border-text-3 {
	-webkit-text-stroke: 3px black;
	color: transparent; 
}

}