

.wrapper-3d-card {
  // margin-top: 10%;
  
  // * {
  //   box-sizing: border-box;
  // }

  .card-acerca {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 36px;
    perspective: 2500px;
    
  }

  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .card-acerca:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(2deg) translateZ(0);
    // box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.50);
    // -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.50);
    // -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.50);
  }

  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
  }
  .wrapper::before {
    top: 0;
    height: 100%;
    // background-image: linear-gradient(
    //   to top,
    //   transparent 46%,
    //   rgba(12, 13, 19, 0.5) 68%,
    //   rgba(12, 13, 19) 97%
    // );
  }
  .wrapper::after {
    bottom: 0;
    opacity: 1;
    // background-image: linear-gradient(
    //   to bottom,
    //   transparent 46%,
    //   rgba(12, 13, 19, 0.5) 68%,
    //   rgba(12, 13, 19) 97%
    // );
  }

  .card-acerca:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
  }

  .card-acerca:hover .wrapper::after {
    height: 120px;
  }
  .title {
    width: 100%;
    transition: transform 0.5s;
    color: white;
    @include media-breakpoint-down(m) {
      width: 70%;
    }
  }
  .card-acerca:hover .title {
    transform: translate3d(0%, -50px, 100px);
  }

  .character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
  }

  .card-acerca:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
  }
  
}

/*--
/*  08. Case 3D Home
/*----------------------------------------*/

// Case Study Section
.section-3d {
  overflow: hidden;
  margin-top: -53%;
  @include media-breakpoint-down(md) {
    margin-top: -28%;
  }
}

// Case Study Wrap
.case-study-wrap {
  margin-top: -10px;
}
// Case Study Slider Wrap
.slider-3d-wrap {
  
  
  & .single-case-3d-2 {
      display: flex;
      align-items: end;
      height: 85vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
  }
      
  & .single-case-3d {
      display: flex;
      align-items: end;
      max-width: 1010px;
      height: 510px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  

      &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(180deg, #FFFFFF00 0%, #010f31 100%);
          width: 100%;
          height: 100%;
          opacity: 0.7;
          z-index: -1;
      }
      &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
          z-index: -1;
      }
      
 
  }
  & .swiper-slide {

      &.swiper-slide-active {
          & .single-case-3d {
              & .case-study-content {
                  display: flex;
              }
          }
      }
  }
}

// Case Study Button
.case-study-btn {
  margin-top: 60px;
  & .btn {
      font-size: 14px;
      padding: 0 35px;
      height: 50px;
      line-height: 46px;
  }
}
// Case Study Arrow
.case-slider-3d-active {
  & .case-study-arrow  {
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 50%;
      transform: translateY(-50%);
      background: $white;
      box-shadow: 0px 5px 57px 0px rgba(0, 0, 0, 0.15);
      color: $primary;
      opacity: 0;
      visibility: hidden;
      
      
      &.swiper-button-prev {
          left: 10%;
          &::after {
              font-size: 20px;
          }
      }
      &.swiper-button-next {
          right: 10%;
          &::after {
              
              font-size: 20px;
          }
      }
      &:hover {
          color: $white;
          background: $primary;
      }
  }
  &:hover {
      & .case-study-arrow {
          opacity: 1;
          visibility: visible;
          &.swiper-button-prev {
              left: 5%;
              
          }
          &.swiper-button-next {
              right: 5%;
              
          }
      }
  }
  @include media-breakpoint-down(m) {
    height: 30vh;
  }
}

