#youtube-background-controls {
  display: none;
}

.youtube-background {
  background-color: $gray-900;
}

.ytplayer-container {
  background-color: $gray-900;
  position: absolute;
  z-index: -2;
  transform: scale(1.2);
}

.background-video {
  background-color: $gray-900;
  background-position: top center;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;

  video,
  source {
    bottom: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
  }
}

.loaded .ytplayer-container {
  display: block;
}

.loaded .placeholder-image {
  opacity: 0;
}

.ytplayer-container {
  bottom: 0;
  height: 100%;
  left: 0;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.placeholder-image {
  height: 100%;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
}

.ytplayer-shield {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.ytplayer-player {
  position: absolute;
}